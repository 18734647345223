@import url('https://fonts.googleapis.com/css?family=Barlow:200,300,400,500');

@mixin for-phone-only {
	@media (max-width: 599px) { @content; }
}
@mixin for-phone-and-tablet-portrait-only {
	@media (max-width: 899px) { @content; }
}
@mixin for-tablet-portrait-up {
	@media (min-width: 600px) { @content; }
}
@mixin for-tablet-portrait-only {
	@media (min-width: 600px) and (max-width: 899px) { @content; }
}
@mixin for-tablet-landscape-up {
	@media (min-width: 900px) { @content; }
}
@mixin for-tablet-landscape-only {
	@media (min-width: 900px) and (max-width: 1199px) { @content; }
}
@mixin for-desktop-up {
	@media (min-width: 1200px) { @content; }
}
@mixin for-desktop-only {
	@media (min-width: 1200px) and (max-width: 1799px) { @content; }
}
@mixin for-big-desktop-up {
	@media (min-width: 1800px) { @content; }
}

.phone-and-tablet-portait-only {	
  @include for-phone-and-tablet-portrait-only {  }
}

/* $$$$$$$$$$$ Variables $$$$$$$$$$$ */

$blackIvory: #231F20;
$warmLightGray: #e4e0da;
$smokeGray: #23353D;


/* $$$$$$$$$$$ Baseline Elements $$$$$$$$$$$ */

html { font-size: 10px; color: $blackIvory; /* This will be our base unit */ }
a { text-decoration: none; }
body { font: normal 300 2rem 'Barlow', sans-serif; }

.text { 
	width: 100%;
	text-align: center;
	margin: auto;
	font: 200 3.2rem 'Barlow', sans-serif;
	strong {
		font-weight: 400;
	}
	@media only screen and (min-width: 720px) {
		padding-top: 3rem; text-align: left; font-size: 4.4rem;
	}
}

.block {
	overflow: hidden;
	padding: 3rem;
	clear: both;
	@media only screen and (min-width: 720px) {
		padding: 0;
	}
}

.container {
	max-width: 1280px;
	margin: auto;
	position: relative;
	clear: both;
}

strong { font-weight: 500;}


/* ########### Header ############ */

header {

	background: $warmLightGray;
	height: 27rem;
	margin: 0;
	position: relative;
	overflow: hidden;

	.header-container {
		
		height: 100%;
        width: 100%;

		.matt {
			position: absolute; bottom: 0; left:-13rem; height: 96%;
		}

		.mst {
			width: 60%; height: 100%; bottom: 0; top: 0; right: 1rem; position: absolute;
		}
	}

	@media only screen and (min-width: 720px) {
		
		.header-container {
			max-width: 1280px;
			.matt { left: 8rem; bottom: 0; height: 100% }
			.mst { max-width: 500px; height: 80%; top: 3rem }
		}
	}

	@media only screen and (min-width: 1024px) {
		.header-container {
			.mst { max-width: none; width: 62%; right: 0; }
            .matt { bottom: -1rem; }
		}
	}
}

/* ########### Intro ############ */

.intro {
	$vennSize: 25rem;
	background-color: $smokeGray;
	margin: 0;
    padding: 0;
	position: relative;
    text-align: center;
    .greeting { letter-spacing: -0.1rem; font: 500 5rem 'Barlow', sans-serif; color: #fff; padding: 1rem 0; }
	.intro-text { color: $warmLightGray; width: 90%; text-align: center; font: 200 2.5rem 'Barlow', sans-serif; margin-bottom: 3rem; }
	.venn { z-index: 0; width: $vennSize; height: $vennSize; }
    .venn-container { 
        
        width: $vennSize; height: $vennSize; margin: 0 auto 2rem auto; 
        background: url('../img/venn-bg.png') center center no-repeat; background-size: $vennSize;}

	@media only screen and (min-width: 720px) {
        
		$introHeight: 46rem; $vennSize: 36rem; height: $introHeight; padding: 0; text-align: left;
        .greeting { padding: 2rem 0; }
		.intro-text { margin: 0; font-size: 4.8rem; width: 50%; text-align: left; padding: 0}
        .venn-container { 
            z-index: 0; position: absolute; right: 0; top: calc($introHeight/2 - $vennSize/2); 
            margin: 0; width: $vennSize; height: $vennSize; background-size: $vennSize }
		.venn { z-index: 0; width: $vennSize; height: $vennSize; top: 0; left: 0; }
        .container { height: 42rem; }
	}
}
/* ########### Brands ############ */

.brands {
    background: $warmLightGray;
    text-align: center;
    .brands-container { padding: 2rem 0; }
    .brands-text { text-align: center; font: 400 2.5rem 'Barlow', sans-serif; padding: 0; margin-bottom: 4rem; }
    .brand-images {
        display: flex; flex-wrap: wrap; flex-direction: row; gap: 2rem; justify-content: center; align-items: center;

        .brands-img { height: 2.5rem }
        .brand-samsung { height: 1.8rem }
        .brand-AMX { height: 2.4rem }
        .brand-harman { height: 7rem; margin-top: -0.5rem }
        .brand-BSS { height: 2.6rem }
        .brand-crown { height: 4rem }
        .brand-roche { height: 4rem }
        .brand-JBL { height: 3.2rem }
    }

    @media only screen and (min-width: 720px) {
        .brands-container { padding: 8rem 0; }
        .brands-text { font-size: 3rem; }
    }
    
}

/* ########### Recent ############ */

.recent {
	background: url('../img/concert-bg.jpg') center center no-repeat;
    background-size: cover;
	
    .recent-container { text-align: center; min-height: 34rem; }
    .harman-text { font: 400 2.5rem 'Barlow', sans-serif; margin: 0 auto; margin-bottom: 3rem; }
	.harman-logo { height: 10rem; }

	@media only screen and (min-width: 720px) {

		$harmanSize: 50rem;
		$currentHeight: 64rem;
		height: $currentHeight;

        .recent-container { padding: 8rem 0; }

		.harman-text { width: 60%; left: 0; text-wrap: balance; font-size: 3rem; }
	}
}

/* ########### Army ############ */

.army {
    
    background: url('../img/army-bg.jpg') center center no-repeat;
    background-size: cover;
    min-height: 25rem;

    .text { color: $warmLightGray; text-align: center; }
    .infantry-text { font: 400 2.7rem 'Barlow', sans-serif; margin-bottom: 2rem; margin-top: 2rem; }
    .army-learnmore-btn { 
        font-weight: 500; text-transform: uppercase; line-height: 70px; font-size: 1.5rem;
        margin: 0 auto; margin-bottom: 5rem;
        display: block; 
        display: none;
        text-align:center; 
        color: $warmLightGray;
        background: url('../img/army-btn.svg') 0 0 no-repeat; background-size: 100%; 
        height: 80px; width: 40%; 
    }
    .unit-text { font: 300 1.5rem 'Barlow', sans-serif; }

    a.army-learnmore-btn:hover { cursor:pointer }

    @media only screen and (min-width: 720px) {
        min-height: 110rem;
        .infantry-text { font-size: 5rem; margin-bottom: 2rem; margin-top: 14rem; }
        .unit-text { font-size: 3rem; margin-top: 16rem; }
    }

}

/* ########### Volunteer ############ */

.volunteer {

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    
    .volunteer-block { 
        height: 30rem; background: #fff;
        padding: 2rem;
        .supporter { 
            margin: 0 auto 2rem auto; 
            text-align: center; font: 500 2.5rem 'Barlow', 'sans-serif'; color: #0A3161; 
            text-wrap: balance;
            @media only screen and (min-width: 720px) {
                font-size: 3.2rem;
                width: 60%;
            }
        }
        .volunteer-stmnt { 
                
            margin: 0 auto 2rem auto; font: 300 2.3rem 'Barlow', 'sans-serif'; color: #0A3161; text-wrap: balance;
            @media only screen and (min-width: 720px) {
                font-size: 2.7rem;
                width: 60%;
            }
        }
        .learnmore-link { 
            background: url('../img/external-link-icon.svg') right center no-repeat; 
            text-transform: uppercase; font: 500 2rem 'Barlow', 'sans-serif'; color: #0A3161; 
            padding-right: 3rem;
            background-size: 10.5%;
            display: inline-block;
            padding-bottom: 3px;
        }

        .learnmore-link:hover { color: #ff0000 }
        
        @media only screen and (min-width: 720px) {
        
            flex-basis: 50%;
            height: 40rem;
            padding: 0;
        }
    }

    .twk { 
        order: 1; text-align: center; display: table;
        
        div { 
            display: table-cell; vertical-align: middle; 
            p { margin-bottom: 2rem; }
        }
        
    }
    .twk-img { 
        
        display: flex; justify-content: center; order: 2; 
        background: url('../img/twk-photo.jpg') center no-repeat; background-size: cover; 

        .twk-logo { 
            width: 50%;
            @media only screen and (min-width: 720px) {
                width: 25%;
            }
        }
    }
    
    .ctl { 
        order: 3; text-align: center; display: table;
        @media only screen and (min-width: 720px) {
            order: 4;
        }
        div { 
            display: table-cell; vertical-align: middle; 
        }
    }
    .ctl-img { 
        
        display:flex; justify-content: center; order: 4; 
        background: url('../img/ctl-photo.jpg') center center no-repeat; background-size: cover;
        
        @media only screen and (min-width: 720px) {
            order: 3;
        }
        .ctl-logo { 
            width: 50%;
            @media only screen and (min-width: 720px) {
                width: 35%;
            }
        }
    }

    @media only screen and (min-width: 720px) {
        
        flex-direction: row;
    }
}

/* ########### Contact ############ */

.contact {

    display: block; position: fixed; bottom: 10px; left: 10px; z-index: 100; 
    overflow: hidden; border: 1px solid #1A282E; background: #1A282E; 
    padding: 2rem; border-radius: 5px; width: 32px; height: 28px; 
    
    h2 {
        height: 32px; background: url('../img/linkedin-icon.svg') 0 center no-repeat; background-size: 32px;
        padding-left: 56px; font: 500 1.5rem 'Barlow', 'sans-serif'; color: #fff;
        width: 27rem;

        -webkit-tap-highlight-color: rgba(255,255,2550,0);

        @media only screen and (min-width: 720px) { font-size: 3rem; width: 60rem; line-height: 26px; }
        @media only screen and (min-width: 720px) { font-size: 3rem }
    }

    h2:hover { cursor: pointer }

    p { 
        color: #fff; padding-left: 56px; font: 400 1.2rem 'Barlow', 'sans-serif'; width: 70rem; font-size: 1.2rem;
        @media only screen and (min-width: 720px) { margin-top: 1rem; font-size: 1.7rem; width: 67rem; }
        @media only screen and (min-width: 1024px) { font-size: 2rem; width: 67rem; }

        a { color: #007EBB; display: block; margin-top: 1rem;
            
            @media only screen and (min-width: 720px) { display: inline; }
        }
    }
}