@import url("https://fonts.googleapis.com/css?family=Barlow:200,300,400,500");
/* $$$$$$$$$$$ Variables $$$$$$$$$$$ */
/* $$$$$$$$$$$ Baseline Elements $$$$$$$$$$$ */
html {
  font-size: 10px;
  color: #231F20; /* This will be our base unit */
}

a {
  text-decoration: none;
}

body {
  font: normal 300 2rem "Barlow", sans-serif;
}

.text {
  width: 100%;
  text-align: center;
  margin: auto;
  font: 200 3.2rem "Barlow", sans-serif;
}
.text strong {
  font-weight: 400;
}
@media only screen and (min-width: 720px) {
  .text {
    padding-top: 3rem;
    text-align: left;
    font-size: 4.4rem;
  }
}

.block {
  overflow: hidden;
  padding: 3rem;
  clear: both;
}
@media only screen and (min-width: 720px) {
  .block {
    padding: 0;
  }
}

.container {
  max-width: 1280px;
  margin: auto;
  position: relative;
  clear: both;
}

strong {
  font-weight: 500;
}

/* ########### Header ############ */
header {
  background: #e4e0da;
  height: 27rem;
  margin: 0;
  position: relative;
  overflow: hidden;
}
header .header-container {
  height: 100%;
  width: 100%;
}
header .header-container .matt {
  position: absolute;
  bottom: 0;
  left: -13rem;
  height: 96%;
}
header .header-container .mst {
  width: 60%;
  height: 100%;
  bottom: 0;
  top: 0;
  right: 1rem;
  position: absolute;
}
@media only screen and (min-width: 720px) {
  header .header-container {
    max-width: 1280px;
  }
  header .header-container .matt {
    left: 8rem;
    bottom: 0;
    height: 100%;
  }
  header .header-container .mst {
    max-width: 500px;
    height: 80%;
    top: 3rem;
  }
}
@media only screen and (min-width: 1024px) {
  header .header-container .mst {
    max-width: none;
    width: 62%;
    right: 0;
  }
  header .header-container .matt {
    bottom: -1rem;
  }
}

/* ########### Intro ############ */
.intro {
  background-color: #23353D;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
}
.intro .greeting {
  letter-spacing: -0.1rem;
  font: 500 5rem "Barlow", sans-serif;
  color: #fff;
  padding: 1rem 0;
}
.intro .intro-text {
  color: #e4e0da;
  width: 90%;
  text-align: center;
  font: 200 2.5rem "Barlow", sans-serif;
  margin-bottom: 3rem;
}
.intro .venn {
  z-index: 0;
  width: 25rem;
  height: 25rem;
}
.intro .venn-container {
  width: 25rem;
  height: 25rem;
  margin: 0 auto 2rem auto;
  background: url("../img/venn-bg.png") center center no-repeat;
  background-size: 25rem;
}
@media only screen and (min-width: 720px) {
  .intro {
    height: 46rem;
    padding: 0;
    text-align: left;
  }
  .intro .greeting {
    padding: 2rem 0;
  }
  .intro .intro-text {
    margin: 0;
    font-size: 4.8rem;
    width: 50%;
    text-align: left;
    padding: 0;
  }
  .intro .venn-container {
    z-index: 0;
    position: absolute;
    right: 0;
    top: 5rem;
    margin: 0;
    width: 36rem;
    height: 36rem;
    background-size: 36rem;
  }
  .intro .venn {
    z-index: 0;
    width: 36rem;
    height: 36rem;
    top: 0;
    left: 0;
  }
  .intro .container {
    height: 42rem;
  }
}

/* ########### Brands ############ */
.brands {
  background: #e4e0da;
  text-align: center;
}
.brands .brands-container {
  padding: 2rem 0;
}
.brands .brands-text {
  text-align: center;
  font: 400 2.5rem "Barlow", sans-serif;
  padding: 0;
  margin-bottom: 4rem;
}
.brands .brand-images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
.brands .brand-images .brands-img {
  height: 2.5rem;
}
.brands .brand-images .brand-samsung {
  height: 1.8rem;
}
.brands .brand-images .brand-AMX {
  height: 2.4rem;
}
.brands .brand-images .brand-harman {
  height: 7rem;
  margin-top: -0.5rem;
}
.brands .brand-images .brand-BSS {
  height: 2.6rem;
}
.brands .brand-images .brand-crown {
  height: 4rem;
}
.brands .brand-images .brand-roche {
  height: 4rem;
}
.brands .brand-images .brand-JBL {
  height: 3.2rem;
}
@media only screen and (min-width: 720px) {
  .brands .brands-container {
    padding: 8rem 0;
  }
  .brands .brands-text {
    font-size: 3rem;
  }
}

/* ########### Recent ############ */
.recent {
  background: url("../img/concert-bg.jpg") center center no-repeat;
  background-size: cover;
}
.recent .recent-container {
  text-align: center;
  min-height: 34rem;
}
.recent .harman-text {
  font: 400 2.5rem "Barlow", sans-serif;
  margin: 0 auto;
  margin-bottom: 3rem;
}
.recent .harman-logo {
  height: 10rem;
}
@media only screen and (min-width: 720px) {
  .recent {
    height: 64rem;
  }
  .recent .recent-container {
    padding: 8rem 0;
  }
  .recent .harman-text {
    width: 60%;
    left: 0;
    text-wrap: balance;
    font-size: 3rem;
  }
}

/* ########### Army ############ */
.army {
  background: url("../img/army-bg.jpg") center center no-repeat;
  background-size: cover;
  min-height: 25rem;
}
.army .text {
  color: #e4e0da;
  text-align: center;
}
.army .infantry-text {
  font: 400 2.7rem "Barlow", sans-serif;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.army .army-learnmore-btn {
  font-weight: 500;
  text-transform: uppercase;
  line-height: 70px;
  font-size: 1.5rem;
  margin: 0 auto;
  margin-bottom: 5rem;
  display: block;
  display: none;
  text-align: center;
  color: #e4e0da;
  background: url("../img/army-btn.svg") 0 0 no-repeat;
  background-size: 100%;
  height: 80px;
  width: 40%;
}
.army .unit-text {
  font: 300 1.5rem "Barlow", sans-serif;
}
.army a.army-learnmore-btn:hover {
  cursor: pointer;
}
@media only screen and (min-width: 720px) {
  .army {
    min-height: 110rem;
  }
  .army .infantry-text {
    font-size: 5rem;
    margin-bottom: 2rem;
    margin-top: 14rem;
  }
  .army .unit-text {
    font-size: 3rem;
    margin-top: 16rem;
  }
}

/* ########### Volunteer ############ */
.volunteer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
}
.volunteer .volunteer-block {
  height: 30rem;
  background: #fff;
  padding: 2rem;
}
.volunteer .volunteer-block .supporter {
  margin: 0 auto 2rem auto;
  text-align: center;
  font: 500 2.5rem "Barlow", "sans-serif";
  color: #0A3161;
  text-wrap: balance;
}
@media only screen and (min-width: 720px) {
  .volunteer .volunteer-block .supporter {
    font-size: 3.2rem;
    width: 60%;
  }
}
.volunteer .volunteer-block .volunteer-stmnt {
  margin: 0 auto 2rem auto;
  font: 300 2.3rem "Barlow", "sans-serif";
  color: #0A3161;
  text-wrap: balance;
}
@media only screen and (min-width: 720px) {
  .volunteer .volunteer-block .volunteer-stmnt {
    font-size: 2.7rem;
    width: 60%;
  }
}
.volunteer .volunteer-block .learnmore-link {
  background: url("../img/external-link-icon.svg") right center no-repeat;
  text-transform: uppercase;
  font: 500 2rem "Barlow", "sans-serif";
  color: #0A3161;
  padding-right: 3rem;
  background-size: 10.5%;
  display: inline-block;
  padding-bottom: 3px;
}
.volunteer .volunteer-block .learnmore-link:hover {
  color: #ff0000;
}
@media only screen and (min-width: 720px) {
  .volunteer .volunteer-block {
    flex-basis: 50%;
    height: 40rem;
    padding: 0;
  }
}
.volunteer .twk {
  order: 1;
  text-align: center;
  display: table;
}
.volunteer .twk div {
  display: table-cell;
  vertical-align: middle;
}
.volunteer .twk div p {
  margin-bottom: 2rem;
}
.volunteer .twk-img {
  display: flex;
  justify-content: center;
  order: 2;
  background: url("../img/twk-photo.jpg") center no-repeat;
  background-size: cover;
}
.volunteer .twk-img .twk-logo {
  width: 50%;
}
@media only screen and (min-width: 720px) {
  .volunteer .twk-img .twk-logo {
    width: 25%;
  }
}
.volunteer .ctl {
  order: 3;
  text-align: center;
  display: table;
}
@media only screen and (min-width: 720px) {
  .volunteer .ctl {
    order: 4;
  }
}
.volunteer .ctl div {
  display: table-cell;
  vertical-align: middle;
}
.volunteer .ctl-img {
  display: flex;
  justify-content: center;
  order: 4;
  background: url("../img/ctl-photo.jpg") center center no-repeat;
  background-size: cover;
}
@media only screen and (min-width: 720px) {
  .volunteer .ctl-img {
    order: 3;
  }
}
.volunteer .ctl-img .ctl-logo {
  width: 50%;
}
@media only screen and (min-width: 720px) {
  .volunteer .ctl-img .ctl-logo {
    width: 35%;
  }
}
@media only screen and (min-width: 720px) {
  .volunteer {
    flex-direction: row;
  }
}

/* ########### Contact ############ */
.contact {
  display: block;
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 100;
  overflow: hidden;
  border: 1px solid #1A282E;
  background: #1A282E;
  padding: 2rem;
  border-radius: 5px;
  width: 32px;
  height: 28px;
}
.contact h2 {
  height: 32px;
  background: url("../img/linkedin-icon.svg") 0 center no-repeat;
  background-size: 32px;
  padding-left: 56px;
  font: 500 1.5rem "Barlow", "sans-serif";
  color: #fff;
  width: 27rem;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
@media only screen and (min-width: 720px) {
  .contact h2 {
    font-size: 3rem;
    width: 60rem;
    line-height: 26px;
  }
}
@media only screen and (min-width: 720px) {
  .contact h2 {
    font-size: 3rem;
  }
}
.contact h2:hover {
  cursor: pointer;
}
.contact p {
  color: #fff;
  padding-left: 56px;
  font: 400 1.2rem "Barlow", "sans-serif";
  width: 70rem;
  font-size: 1.2rem;
}
@media only screen and (min-width: 720px) {
  .contact p {
    margin-top: 1rem;
    font-size: 1.7rem;
    width: 67rem;
  }
}
@media only screen and (min-width: 1024px) {
  .contact p {
    font-size: 2rem;
    width: 67rem;
  }
}
.contact p a {
  color: #007EBB;
  display: block;
  margin-top: 1rem;
}
@media only screen and (min-width: 720px) {
  .contact p a {
    display: inline;
  }
}

